import React from 'react'
import Layout from '../components/Layout'

export default function success() {
    return (
        <Layout>
            <div className="text-2xl flex justify-center px-6 py-20 text-blue-900 font-bold">Thank you for contacting us! We'll be in touch soon.</div>
        </Layout>
    )
}
